<template>
  <v-container fluid class="ma-0 pa-0">
    <v-layout row wrap :gradient="$vuetify.breakpoint.smAndUp" :primary="$vuetify.breakpoint.xsOnly" :pt-5="$vuetify.breakpoint.smAndUp" :px-5="$vuetify.breakpoint.smAndUp">
      <v-flex xs12 max-width-1400 full-width ma-auto>
        <v-layout row wrap>
          <v-flex xs12 sm8 md6 :px-4="$vuetify.breakpoint.xsOnly" :py-4="$vuetify.breakpoint.xsOnly">
            <v-card color="transparent" flat tile>

              <v-card-text class="pa-0" v-if="!g_isLoading">
                <h1 class="graphik-bold font-35 mb-2 mt-0 white--text">{{ $ml.get('thankyou_title') }}</h1>

                <h2 class="graphik-light font-17 mb-4 white--text">{{ $ml.get('thankyou_description') }}</h2>

                <v-layout align-center justify-start mb-4>
                  <v-img
                    v-if="policy.company_internal_name"
                    max-width="250px"
                    class="mr-5"
                    contain
                    :src="company_logo"
                    :lazy-src="company_logo"
                  />

                  <v-btn 
                    color="pantene" 
                    depressed
                    :loading="g_isLoading"
                    class="px-2 normalcase graphik-bold-italic ma-0 border-radius-5 white--text"
                    :disabled="g_isLoading"
                    @click="$goTo('/poliza/' + $route.params.policy, 'goto_view_policy')"
                  >{{ $ml.get('general_view_policy') }}</v-btn>
                </v-layout>

                <p class="graphik-bold font-20 mb-2 mt-5 white--text" v-if="policy.car">{{ policy.car }}</p>
                <p class="graphik-bold font-20 mb-2 mt-5 white--text" v-else>{{ policy.policy_type }}: {{ policy.policy_type_plan }}</p>
                <p class="graphik-bold font-20 mb-5 white--text">{{ $ml.get('fields_policy_number') }}: {{ policy.number }}</p>

                <v-layout align-center justify-start my-4 v-if="$vuetify.breakpoint.smAndUp && !g_auth.access_token">
                  <p class="graphik-light font-20 mb-0 mr-5 white--text">{{ $ml.get('login_no_account') }}</p>
                  
                  <v-btn 
                    color="primary" 
                    depressed
                    :loading="g_isLoading"
                    class="px-2 normalcase graphik-bold-italic ma-0 border-radius-5 white--text"
                    :disabled="g_isLoading"
                    @click="$goTo('/registro/' + $route.params.policy, 'goto_register')"
                  >{{ $ml.get('general_create_profile') }}</v-btn>
                </v-layout>

              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm4 md6 d-flex align-end :px-4="$vuetify.breakpoint.xsOnly" :pt-4="$vuetify.breakpoint.xsOnly">
            <v-img
              width="100%"
              contain
              position="center bottom"
              :src="image"
              :lazy-src="image"
            />
          </v-flex>

          <v-flex xs12 v-if="$vuetify.breakpoint.xsOnly" secondary :pa-4="$vuetify.breakpoint.xsOnly">
            <v-layout align-center justify-start my-4>
              <p class="graphik-light font-20 mb-0 mr-5 white--text">{{ $ml.get('login_no_account') }}</p>
              
              <v-btn 
                color="primary" 
                depressed
                :loading="g_isLoading"
                class="px-2 normalcase graphik-bold-italic ma-0 border-radius-5 white--text"
                :disabled="g_isLoading"
                @click="$goTo('/registro/' + $route.params.policy, 'goto_register')"
              >{{ $ml.get('general_create_profile') }}</v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Thankyou',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_THANK_YOU
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: process.env.VUE_APP_METATAGS_DESCRIPTION_THANK_YOU
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: process.env.VUE_APP_METATAGS_URL + '/gracias'
      },
    ],
    link: [
      // SEO
      {
        vmid: 'canonical',
        rel: 'canonical',
        href: process.env.VUE_APP_METATAGS_URL + '/gracias'
      }
    ]
  },
  data () {
    return {
      policy: {},
      image: require('@/assets/img/images/thank_you.png'),
    }
  },
  computed: {
    company_logo () {
      if (!this.policy.company_internal_name) {
        return null
      }
      return require('@/assets/img/companies/' + this.policy.company_internal_name + '-white-full.png')
    }
  },
  methods: {
    // CRUD
    getPolicy () {
      this.g_isLoading = true
      this.$api.getPolicy(
        this.$route.params.policy,
        response => {
          this.policy = response.body.data
          if (Object.keys(this.$route.query).length) {
            this.sendParams()
          } else {
            this.createDocument()
          }
        },
        response => this.$errorHandling(response).then(() => {
          this.getPolicy()
        }, () => {})
      )
    },
    createDocument () {
      this.$api.createDocument(
        this.$route.params.policy,
        () => {
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.createDocument()
        }, () => {})
      )
    },
    sendParams () {
      this.$api.payPolicy(
        this.$route.params.policy,
        this.$route.query,
        () => {
          this.$analytics('checkout_payment_outside_success')
          this.createDocument()
        },
        response => this.$errorHandling(response).then(() => {
          this.$analytics('checkout_payment_outside_failure')
          this.sendParams()
        }, () => {})
      )
    }
  },
  mounted () {
    this.getPolicy()
  }
}
</script>

<style scoped>
  .gradient {
    background-image: linear-gradient(#4C3AA1, #271B4D) !important;
  }
</style>
